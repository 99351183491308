

.brand{
    display: flex;
    flex-flow: row wrap;  
    justify-content: space-between;
    align-items: stretch;
    // background-color: red;
    @include respond(phone){
        flex-direction: column;
        // justify-content: center;
        // align-items: fle;
    }
    &__logo{
        position: relative;
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img{
            // border-radius: 4px;
            object-fit: cover;
            object-position: center center;
            min-width: 200px;
            max-width: 200px;
            min-height: 200px;
            max-height: 200px;
            border-radius: 50%;
            // width: 100%;
        }
        &__edit-icon{
            position: absolute;
            top: 35px;
            right: 35px;
            width: 29px;
            cursor: pointer;
            fill: none;
            & *{
                transition: all 300ms;
            }
            &:hover{
                & *{
                    stroke: $primary;
                }
            }
            // background-color: red;
        }
        @include respond(phone){
            margin: auto;
        }
    }
    &__info{
        flex: 1;
        margin: 40px 25px;
        @include respond(phone){
            margin-right: 0;
            margin-left: 0;
            margin-top: 20px;
        }
        &__container{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            &__name{
                font-family: $round;
                font-weight: 700;
                font-size: 36px;
                line-height: 43px;
                color: rgba(0, 0, 0, 0.8);
                text-transform: capitalize;
            }
            &__tag{
                background-color: $primary;
                color: $onPrimary;
                padding: 5px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                border-radius: 10px;
                margin-left: 10px;
                cursor: pointer;
                &:hover{
                    background-color: $primaryHover;
                }
            }
        }
        &__para{
            font-family: $subTitle;
            font-weight: normal;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: $onSurface-secondary;
            max-width: 400px;
            word-break: break-all;
           
        }
    }
    &__progress{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 50px;
        @include respond(phone){
            margin: 0;
            flex-direction: row;
            justify-content: space-evenly;
            // align-items: flex-end;
        }
        &__reviews{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            &__count{
                font-weight: 600;
                font-size: 60px;
                line-height: 89.88px;
                color: $primary;
            }
            &__text{
                font-size: 22px;
                color: $primary;
            }
            &__reviewCount{
                font-size: 28px;
                font-weight: 600;
            }
            h3{
                font-family: $title;
                color: $primary;
            }
        }
        &__ratings{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            & span {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }
            img{
                width: 100px;
            }
            p{
                color: #FFCC0D;
                font-family: $title;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}

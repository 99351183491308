.header__searchbar{
    background-color: white;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    &-container{
        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        // overflow: hidden;
    }
    &__input{
        width: 100%;
        // background-color: teal;
        & input{
            font-size: 20px;
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: transparent;
        }
    }
    &__dropdown{
        &-container{
            box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
            background-color: white;
            position: absolute;
            width: 100%;
            max-height: 300px;
            overflow: auto;
            top: 100%;
            margin-top: 3px;
            border-radius: 10px;
            z-index: 75;
        }
        &-item{
            padding: 20px 20px;
            transition: all 300ms;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            & img{
                max-width: 50px;
                max-height: 50px;
                min-height: 50px;
                min-width: 50px;
                object-fit: contain;
                border-radius: 4px;
            }
            & p{
                margin-left: 20px;
            }
            &:not(:last-child){
                border-bottom: 1px solid rgb(219, 219, 219);
            }
            &:hover{
                background-color: rgb(235, 235, 252);
            }
        }
        &-empty{
            padding: 20px;
        }
    }
}
.homepage {
  position: relative;
  @include respond(phone) {
    margin-top: 35px;
  }
  &__compare-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
  }
  &__searchbar-container {
    max-width: 750px;
    margin: 20px auto;
    position: relative;
    @include respond(phone) {
      margin: 20px;
      display: none;
    }
  }
  &__blue-spiral {
    position: absolute;
    z-index: -10;
    top: 0;
    right: 5%;
    @include respond(phone) {
      display: none;
    }
  }
  &__blue-spiral2 {
    position: absolute;
    z-index: -10;
    left: 0;
    top: 100px;
    @include respond(phone) {
      display: none;
    }
  }
  &__yello-zigzag {
    position: absolute;
    z-index: -10;
    top: 0;
    left: 5%;
    @include respond(phone) {
      display: none;
    }
  }
  &__yello-zigzag2 {
    position: absolute;
    z-index: -10;
    top: 50%;
    left: 5%;
    @include respond(phone) {
      display: none;
    }
  }
  &__blue-zigzag {
    position: absolute;
    z-index: -10;
    bottom: 0;
    left: 5%;
    @include respond(phone) {
      display: none;
    }
  }
  &__vertical-dot {
    position: absolute;
    z-index: -10;
    top: 20%;
    left: 3%;
    @include respond(phone) {
      display: none;
    }
  }
  &__vertical-dot2 {
    position: absolute;
    z-index: -10;
    bottom: 0;
    right: 5%;
    transform: translate(50%, 50%);
    @include respond(phone) {
      display: none;
    }
  }
  &__vertical-dot3 {
    display: none;
    position: absolute;
    // color: red;
    z-index: -10;
    right: 0;
    top: 0;
    @include respond(phone) {
      display: none;
    }
  }
  &__horizantal-dot {
    position: absolute;
    z-index: -10;
    bottom: 3.4%;
    right: 3%;
    @include respond(phone) {
      display: none;
    }
  }
  &__horizantal-dot2 {
    position: absolute;
    z-index: -10;
    right: 5%;
    top: 0;
    transform: translate(0, -50%);
    @include respond(phone) {
      display: none;
    }
  }
}

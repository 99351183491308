.forgot__password{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: $onSurface-secondary;
    z-index: 100;
    &__container{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        max-width: 500px;
        padding: 20px 10px;
        background-color: $background;
        border-radius: 10px;
        position: relative;
        width: 300px;
        &__inputs{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            & label{
                font-weight: bold;
                font-size: 15px;
                color: $onSurface-secondary;
            }
            & input{
                width: 100%;
                outline: none;
                border: 0px solid;
                border-bottom: 1px solid $onSurface-secondary;
                padding: 10px 4px;
            }
        }
        &__button{
            background-color: $primary;
            color: $onPrimary;
            width: 100%;
            margin: 10px 0px;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            border: none;
            outline: none;
            position: relative;
        }
        &__icon{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            padding: 10px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            cursor: pointer;
            &__svg{
                color: white;
            }
        }
    }
}
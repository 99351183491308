.chart__div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0px;
  padding: 3px 3px;
  overflow-x: auto;
  @include respond(phone) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__date-picker1 {
    width: 30%;
    margin: 30px 0px;
    margin-left: 50px;
    @include respond(phone) {
      margin-left: 0;
    }
  }
  &__chartWidth {
    width: 500px;
    height: 300px;
    @include respond(phone) {
      width: 99%;
      height: 300px;
    }
  }
  &__first-chart {
    // margin-right: 50px;
    background-color: $background;
    padding: 12px 16px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 20px;
    @include respond(phone) {
      margin-bottom: 20px;
    }
    &__intro {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__second-chart {
    background-color: $background;
    padding: 12px 16px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 20px;
    &__intro {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.brand__review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 800px;
  margin: auto;
  // z-index: 2;
  &__uploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  &__image {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &__uploaded {
      width: 100px;
      border-radius: 10px;
      transition: all 300ms;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  &__comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 60px;
    &__intro {
      // background-color: red;
      padding: 10px 14px;
      &__profile {
        display: flex;
        flex-direction: row;
        &__img {
          width: 50px;
          height: 50px;
        }
        &__name-rating {
          display: flex;
          flex-direction: column;
          margin-right: auto;
          margin-left: 10px;
          &__comment {
            max-width: 700px;
            color: $onSurface-secondary;
          }
          & h2 {
            color: $onSurface-primary;
          }
          & h3 {
            color: $primary;
          }
        }
      }
    }
  }
  &__replies {
    // background-color: salmon;
    &__reply {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 14px;
      & img {
        width: 30px;
        height: 30px;
      }
      &__comment {
        margin-left: 10px;
        color: $onSurface-secondary;
        &__like {
          display: flex;
          flex-direction: row;
          align-items: center;
          & h4 {
            padding-right: 10px;
          }
        }
      }
    }

    &__my-comment {
      display: flex;
      flex-direction: row;
      width: 100%;
      & img {
        width: 50px;
      }
      & input {
        flex: 1;
        margin-left: 10px;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        border: 0px solid;
        &:focus {
          border: 1px solid;
        }
      }
    }
  }
}

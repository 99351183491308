.details{
    max-width: 1050px;
    margin: auto;
    margin-bottom: 50px;
    &__reviews{
        &__comment{
            width: 1100px;
            max-width: 90%;
            margin: auto;
        }
    }
}
.details__list{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: stretch;
    &__filters{
        @include respond(phone){
            align-self: center;
            margin: auto;
            margin-top: 20px;
        }
    }
}
.details ul{
    // flex-basis: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    text-align: center; 
    position: relative;
    width: 40%;
    @include respond(phone){
        width: 90%;
        margin: auto;
    }
    &::after{
        content: "";
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: -1;
    }
}
.details ul li{
    list-style: none;
    display: inline-block;
    padding: 8px 0;
    &:not(:first-child):not(:last-child){
        padding: 8px 20px;
    }
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    
}
.details ul li:active{
    color: $primary
}

.details__list__click{
    border-bottom: 4px solid $primary;
    border-collapse: collapse;
    color: $primary;  
}

.user__profile{
    &__vertical-dots{
        position: absolute;
        z-index: -1;
        top: 50%;
        @include respond(phone){
            display: none;
        }
    }
    &__horizantal-dots{
        position: absolute;
        z-index: -1;
        top: 5%;
        right: 10%;
        @include respond(phone){
            display: none;
        }
    }
    &__blue-spiral{
        position: absolute;
        z-index: -1;
        top: 30%;
        right: 0;
        @include respond(phone){
            display: none;
        }
    }
    &__zig-zag-component{
        position: absolute;
        z-index: -1;
        top: 5%;
        left: 10%;
        @include respond(phone){
            display: none;
        }
    }
    &__blue-zig-zag-component{
        position: absolute;
        z-index: -1;
        bottom: 5%;
        left: 10%;
        @include respond(phone){
            display: none;
        }
    }
}
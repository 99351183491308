.topReview {
  max-width: 1000px;
  margin: 40px auto;
  max-width: 100%;
  overflow: hidden;

  padding-bottom: 100px;
  &__review-container {
    display: flex;
    overflow: visible !important;
  }
  &__item {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 300px;
    min-width: 300px;
    height: 390px;
    min-height: 390px;
    box-shadow: 0px 30px 80px rgba(13, 19, 19, 0.07);
    border-radius: 12px;
    margin: 40px;
    position: relative;
    &:nth-child(2n + 1) {
      top: 100px;
    }
    &-dots {
      position: absolute;
      left: 100%;
      top: 50%;
      // transform: translateX(-100%);
      // transform: translate(-100%, -50%);
      z-index: -10;
    }
    &__heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__userName {
        text-transform: capitalize;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.8);
        font-size: 18px;
        font-weight: 600;
        font-family: $round;
        &:hover {
          text-decoration: underline;
        }
      }
      &__userImage {
        margin-right: 10px;
        & img {
          height: 50px;
          aspect-ratio: 1;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    &__body {
      margin: 20px 0;
      height: 300px;
      &__brandName {
        color: #35c9ce;
        font-size: 20px;
        &:hover {
          text-decoration: underline;
        }
        & p {
          margin-bottom: 10px;
          color: #357bce;
        }
      }
      &__reviewText {
        max-width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        font-size: 16px;
        font-weight: 400;
        font-family: $subTitle;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &__footer {
      display: flex;
      align-items: flex-start;
      &__rating {
        margin-left: 10px;
        line-height: 17px;
      }
    }
  }
}

.ticker {
  overflow: visible !important;
}

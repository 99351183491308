.header__notification{
    &-container{
        position: relative;
        z-index: 100;
    }
    margin-top: 20px;
    width: 300px;
    // height: 500px;
    background-color: $background;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    right: 0;
    max-height: 400px;
    overflow: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 12px 16px;
    @include respond(phone){
        transform: translateX(40%);
    }
    &__empty{
        background-color: f;
        position: absolute;
        background-color: $background;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        top: 40px;
        right: 0;
        width: 300px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__counter{
        position: absolute;
        top: 0;
        right: 0;
        background-color: $primary;
        color: $onPrimary;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        font-size: 10px;
        aspect-ratio: 1;
        border-radius: 50%;
        transform: translate(25%, -25%);
    }
    &__item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        // background-color: red;

        &__date{
            font-size: 15px;
            color: $onSurface-secondary;
        }
        &__notification{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            box-shadow: rgba(189, 189, 189, 0.5) 0px 1px 4px;
            border-radius: 4px;
            margin-bottom: 10px;
            padding: 10px 9px;
            
            &:hover{
                background-color: ghostwhite;
            }
            &__logo{
                margin-top: 5px;
                margin-right: 10px;
            }
            & img{
                max-width: 30px;
                max-height: 30px;
                object-fit: contain;
                margin-right: 10px;
            }
            & p{
                flex: 1;
                font-size: 13px;
                text-align: left;
                text-transform: capitalize;
            }
        }
    }
    
}
.brand__information {
  display: flex;
  flex-flow: row wrap;
  border-radius: 10px;
  max-width: 1200px;
  box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.07);
  justify-content: flex-start;
  margin: auto;
  padding: 10px 14px;
  background-color: $background;
  @include respond(phone) {
    justify-content: center;
  }
  // align-items: flex-start;

  &__compare {
    padding: 10px 20px;
    margin-right: auto;
    display: flex;
    align-items: center;
    & button {
      width: 200px;
      background-color: $primary;
      color: $onPrimary;
      border: 1px solid transparent;
      outline: none;
      height: 50px;
      border-radius: 8px;
      transition: all 300ms;
      margin-bottom: 50px;
      &:hover {
        background-color: transparent;
        border-color: $primary;
        color: $primary;
      }
    }
  }
  //hello
  &__review-button {
    align-self: flex-start;
    justify-self: flex-end;
    border-radius: 8px;
    border: none;
    color: $onPrimary;
    font-size: 20px;
    outline: none;
    transition: all 300ms;
    border: 1px solid transparent;
    background-color: $primary;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 15px;
    &:hover {
      background-color: #fff;
      border-color: $primary;
      color: $primary;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transform: translateY(-2px) scale(1.01);
    }
  }
  &__img-ratings {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    @include respond(phone) {
      margin-bottom: 20px;
    }
    // background-color: red;
    &__img {
      max-height: 150px;
      min-height: 150px;
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
      border-radius: 28px;
      // border-radius: 50%;
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: max-content;
      & p {
        color: $primary;
        font-weight: 0;
        font-size: 28px;
        line-height: 42px;
        transition: all 300ms;
        font-family: baloo;
        @include respond(phone) {
          max-width: 300px;
          font-size: 23px;
          text-align: center;
        }
      }

      &__stars {
        margin-top: 10px;
        & p {
          background-color: red;
          @include respond(phone) {
            max-width: 100px;
            background-color: red;
          }
        }
      }
    }
  }
  &__title-about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
    // width: 100%;
    align-self: stretch;
    margin-right: auto;
    margin-left: 10px;

    @include respond(phone) {
      margin-bottom: 20px;
    }
    &__premeired {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      & h3 {
        color: $primary;
        font-weight: bold;
        font-size: 22px;
        line-height: 33px;
        text-transform: capitalize;
        max-width: 300px;
        overflow-wrap: break-word;
      }
      &__tag {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        color: $onPrimary;
        width: 100px;
        padding: 5px 0px;
        border-radius: 10px;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          background-color: $primaryHover;
        }
      }
    }
    &__para {
      max-width: 350px;
      color: $onSurface-secondary;
      font-weight: normal;
      font-size: 18px;
      line-height: 31px;
    }
  }
}

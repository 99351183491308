.update__profile{
        width: 100%;
        padding: 12px 16px;
        background-color: $primary;
        text-align: center;
        border-radius: 8px;
        transition: 300ms;
        cursor: pointer;
        &:hover{
            background-color: $primaryHover;
        }
    &__button{
        color: $onPrimary;
    }
}
//ANCHOR Navbar styling
.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  &-container {
    // z-index: 50;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px 50px;
    width: 100%;
    @include respond(phone) {
      padding: 5px 0px;
    }
  }
  &__emailVerification {
    // z-index: 50;
    width: 100%;
    background-color: $primary;
    color: $onPrimary;
    text-align: center;
    padding: 5px 0px;
    & button {
      border: none;
      text-decoration: underline;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      color: $onPrimary;
    }
  }
}

//SECTION inner container
.nav__innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

//ANCHOR search bar container
.nav__searchdiv {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
}

.nav__searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px 0;
  position: relative;
  width: 350px;

  &__searchIcon {
    margin: 0 10px;
  }
}

.nav__searchBar input,
.nav__searchBar img {
  display: inline-block;
}
.nav__searchBar input {
  background-color: transparent;
  border: 0px solid;
  flex: 1;
  padding: 0 10px;
}
.nav__searchBar input:focus {
  outline: none;
}

.nav__searchBar img {
  margin: 0 10px;
}

.searchBar__close__icon {
  display: none;
}
.nav__lock {
  @include respond(phone) {
    // display: none;
  }
  margin: 0 10px;
  font-size: 25px;
}
.nav__links ul li::after {
  content: "";
  width: 0%;
  height: 2px;
  background: $primary;
  display: block;
  margin: auto;
  transition: 0.3s;
}
.nav__links ul li:hover::after {
  width: 100%;
}
.nav__links__button__after:hover::after {
  width: 0%;
}

/* nav icon and website name */
.nav__icon {
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  text-decoration: none;
  &__svg {
    margin: 0 5px;
  }
  &__container {
    position: relative;
    min-width: 30px;
    &__mobile {
      display: none;
      @include respond(phone) {
        display: block;
      }
    }
  }
}
.nav__icon h1 {
  color: $primary;
}
.nav__icon img,
.nav__icon h1 {
  display: inline-block;
}

/* nav Search Bar */

.nav__search__results {
  display: inline-block;
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: 100%;
  width: 100%;
  /* padding: 0px 10px; */
  background-color: rgba($background, 0.9);
  max-height: 300px;
  overflow-y: auto;
  color: #272727;
  z-index: 100;
  // backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-top: 3px;
}

.nav__search__result__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  height: 60px;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  color: inherit;
}
.nav__search__result__item:hover {
  background-color: ghostwhite;
}
.nav__search__result__item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

/* nav links of Login and Register */
.nav__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  &__loader {
    width: 100px;
    text-align: center;
  }
  @include respond(desktop) {
    // min-width: 300px;
  }
  &__button {
    width: 100px;
    padding: 10px 20px;
    background-color: $primary;
    color: $onPrimary;
    border-radius: 10px;
    border: 0px solid;
    cursor: pointer;
    &:hover {
      background-color: $primaryHover;
    }
  }
}
.nav__links ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @include respond(phone) {
    padding: 10px 0;
  }
}
.nav__links ul li {
  list-style: none;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
  text-align: center;
  // min-width: 100px;
  z-index: 50;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: red;
  @include respond(phone) {
    padding: 8px 8px;
  }
}
.nav__links__link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
  position: relative;
  z-index: 50;

  &__h4 {
    color: $onSurface-secondary;
    font-weight: 700;
    @include respond(phone) {
      // width: min-content;
    }
  }

  &__user-login {
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform-origin: top left;
    transform: scale(1);
    width: 100px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-top: 10px;
    border-radius: 4px;
    // backdrop-filter: blur(4px);
    background-color: #fff;
    transition: all 300ms;
    z-index: 75;
    &-hide {
      transform: scale(0);
    }
    @include respond(phone) {
      border-radius: 0px;
      background-color: $background;
      width: 100px;
      padding: 2px 0;
      border-radius: 4px;
    }
    // background-color: blue;
    &__link1 {
      // border-bottom: 1px solid $onSurface-secondary;
      padding: 4px 8px;
      transition: 2.3s;
      width: 100%;

      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &:hover {
          color: $primary;
        }
      }
      @include respond(phone) {
        padding: 2px 8px;
      }
    }
    &__link2 {
      padding: 4px 8px;
      width: 100%;
      &:hover {
        color: $primary;
      }
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      @include respond(phone) {
        padding: 2px 8px;
      }
    }
  }
}
.nav__links__icon {
  margin-top: 4px;
}

.nav__links ul li a {
  text-decoration: none;
  color: inherit;
}

/* other elements of navbar wose display is none in laptop mode */
.nav__search__icon {
  display: none;
}
.nav__open__icon {
  display: none;
}

.nav__close__icon {
  display: none;
}

.login__indicator {
  font-size: 100px;
}

.loading__indicator__div {
  position: fixed;
  top: 50%;
  bottom: 50%;
  right: 50%;
  &__mask {
    background-color: rgba(0, 0, 0, 0.247);
    position: fixed;
    inset: 0;
  }
  // transition: transform(-50%, 50%);
}

/* @media Queries */

@media (max-width: 700px) {
  .nav {
    padding: 10px;
  }
  .nav__searchdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    // padding: 10px;
    transition: 300ms;
  }
  .nav__links ul {
    width: 70%;
  }
  .nav__searchBar {
    width: 100%;
  }
  .nav__links {
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    text-align: left;
    // z-index: 2;
    transition: 300ms;
    right: 0;
    // overflow:hidden;
    transform-origin: right center;
    @include respond(phone) {
      // overflow: visible;
    }
  }
  .nav__links-show {
    // width: 100%;
    transform: scaleX(1);
  }
  .nav__links-hide {
    // width: 0;
    transform: scaleX(0);
  }
  .nav__open__icon {
    display: block;
    cursor: pointer;
    width: 30px;
  }
  .nav__search__icon {
    display: block;
    cursor: pointer;
    width: 30px;
  }
  .nav__close__icon {
    display: block;
    /* position: absolute; */
    cursor: pointer;
    color: black;
    // width: 30px;
    // height: 30px;
    // margin-left: auto;
    margin: 0 10px;
    // font-size: 20px;
    /* top: 10px;
        right: 40px; */
  }
  .searchBar__close__icon {
    display: inline-block;
    align-self: flex-start;
    cursor: pointer;
    color: $onSurface-secondary;
    margin: 0 10px;
    font-size: 25px;
  }
  .nav__icon {
    flex: 0 1 auto;
  }
  .nav__innerContainer {
    justify-content: space-between;
  }
}

.warning {
  &__value {
    // width: 200px;
    color: $primary;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    width: auto;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-transform: capitalize;
    background-color: $background;
    font-family: $round;
    font-weight: 700;
    margin-top: 50px;
    // margin: auto;
    @include respond(phone) {
      //   width: max-content;
      width: 100%;
      font-size: 10px;
    }
  }
}

.dashboard__list{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 5px;
        // max-width: 1100px;  
        // margin: auto;
        &__brand__link{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 50px;
          @include respond(phone){
            flex-direction: column;
          }
          &__filters{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: 55%;
            @include respond(phone){
              flex-direction: column;
            }
            &__item{
              margin-right: 10px;
              @include respond(phone){
               margin-top: 10px;
              }
            }
          }
        }
        @include respond(phone){
            justify-content: center;
        }
        &__chart{
          width: 1200px;
          max-width: 100%;
        }
        & ul{ 
          position: relative;
          width: 45%;
            &::after{
              content: '';
              position: absolute;
              bottom: 1px;
              width: 100%;
              height: 3px;
              background-color: gainsboro;
              z-index: -1;
            }
            display: flex;
            align-items: center;
            width: 50%;
            justify-content: space-between;
            @include respond(phone){
                margin-bottom: 20px;
                flex-direction: row;
                width: 100%;
                justify-content: space-around;
            }
            & li{
                display: inline-block;
                list-style: none;
                text-align: center;
                width: 100px;
                font-style: $title;
                cursor: pointer;
                padding: 8px 0px;
            }
            .list__click{
                border-bottom: 5px solid $primary;
                border-collapse: collapse;
                color: $primary;
            }
        }

        &__filter{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #F8F8F8;
            padding: 12px 15px;
            width: 300px;
            &__icon{
                cursor: pointer;
                color: #35C9CE;
            }
            input{
                flex: 1;
                border: 0px;
                background-color: transparent;
                outline: none;
            }
        }

        &__settings{
           
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px; 
          width: 500px; 
          max-width: 100%;
          &__item{
            display: flex;
            justify-content: space-between;
            margin: 10px 5px;
            border-bottom: 1px solid $onSurface-secondary;
            width: 100%;
            &__email{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;  
              padding: 10px 0px;
              width: 100%;
              & h3{
                font-style: $title;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: $onSurface-primary;
              }
              & h4{
                font-style: $subTitle;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
                color: $onSurface-secondary;
              }
            }
          }
          &__items{
            display: flex;
            justify-content: space-between;
            margin: 10px 5px;
            border-bottom: 1px solid $onSurface-secondary;
            width: 100%;
            &__name, &__brand-name,&__category-name{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;  
              padding: 10px 0px;
              & h3{
                font-style: $title;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: $onSurface-primary;
              }
              & h4{
                font-style: $subTitle;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
                color: $onSurface-secondary;
              }
            }
            &__brand-name{
              & h4{
                text-transform: capitalize;
              }
            }
            &__category-name{   
              & h4{
                text-transform: capitalize;
              }
            }
          }
        }

        &__update-settings{
          margin-top: 20px;  
        }
    }


.cancel__button__brand{
  background-color: $primary;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  &__icon{
    align-self: center;
  }
}

.brand__reviews__container{
  // width: 1100px;
  width: 100%;
  margin: 50px 0px;
  padding: 0 10px;
}
.reviewComponent {
  width: 100%;
  border-radius: 2px;
  position: relative;
  &-container {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 0 0 10px 0;
    &__upper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
    &__left {
      text-align: center;
      display: flex;
      flex-direction: column;
      &__lineBox {
        // width: 100%;
        // flex: 1;
        position: relative;
        &__line {
          position: absolute;
          width: 1px;
          border-left: 2px solid rgb(221, 221, 221);
          height: calc(100% - 140px);
          // background-color: rgb(221, 221, 221);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &__right {
      flex: 1;
      padding-left: 15px;
    }
  }
  &__userImage {
    // background-color: blue;
    & img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: top center;
      aspect-ratio: 1;
      margin: 5px;
      border-radius: 50%;
    }
  }
  &__profile {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__intro {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-around;
      // padding: 0px 20px;
      &__name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-transform: capitalize;
        margin-right: 15px;
        &-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          &-svg {
            margin: 0 5px;
            @include respond(phone) {
              width: 30px;
              height: 30px;
            }
          }
          &-editsvg {
            margin: 0 5px;
            cursor: pointer;
            transition: all 300ms;
            transform: translateZ(0);
            backface-visibility: hidden;
            @include respond(phone) {
              width: 40px;
              height: 40px;
            }
            &:hover {
              transform: translateZ(0) scale(1.2);
            }
          }
        }
        & a {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: black;
        }
        &__rating {
          display: flex;
          flex-direction: row;
          align-items: center;
          & h4 {
            color: $primary;
            padding: 0 5px;
            padding-bottom: 4px;
          }
        }
      }
      &__status {
        display: flex;
        flex-flow: row wrap;
        &__review-label,
        &__thank-label {
          background-color: #35c9ce;
          font-weight: 600;
          border-radius: 10px;
          color: white;
          align-self: flex-start;
          margin-top: 5px;
          pointer-events: none;
          & p {
            padding: 2px 15px;
            padding-bottom: 5px;
          }
        }
        &__thank-label {
          background-color: rgb(81, 255, 0);
          margin-left: 10px;
          @include respond(phone) {
            margin-left: 0px;
          }
        }
      }
    }
    &__pics {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      // flex: 1;
      @include respond(phone) {
        justify-content: center;
        margin: 15px 0px;
      }
    }
  }
  &__text {
    color: $onSurface-secondary;
    width: 100%;
    // max-width: 100%;
    width: 100%;
    // margin: auto;
    // margin-left: 30px;
    text-align: left;
    align-self: flex-end;
    // margin-left: 50px;
    padding-top: 20px;
    padding-bottom: 10px;
    &__para {
      max-width: 800px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      @include respond(phone) {
        max-width: 250px;
      }
    }
    &__edit {
      outline: none;
      background-color: $background;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      width: 100%;
      resize: none;
      border-radius: 4px;
      padding: 5px;
      &__buttons {
        display: flex;
        flex-direction: row;
        // margin: 10px;
        & button {
          margin: 0 10px;
          background-color: transparent;
          outline: none;
          border: none;
          border-radius: 4px;
          padding: 10px 25px;
          border: 1px solid transparent;
          transition: all 300ms;
          cursor: pointer;
        }
        & button:first-child {
          background-color: #357bce;
          color: white;
          &:hover {
            background-color: white;
            color: #357bce;
            border-color: #357bce;
          }
          @include respond(phone) {
            margin-bottom: 2px;
          }
        }
        & button:last-child {
          background-color: white;
          color: #357bce;
          border-color: #357bce;
          &:hover {
            background-color: #357bce;
            color: white;
          }
        }
      }
    }
    @include respond(phone) {
      margin-left: 0px;
    }
  }
  &__buttons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: right;
    &__likeCount {
      font-size: 20px;
      padding-bottom: 6px;
      color: grey;
      margin: 0 10px;
      pointer-events: none;
    }
    &__button {
      font-size: 15px;
      cursor: pointer;
      transition: all 300ms;
      @include respond(phone) {
        font-size: 20px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      backface-visibility: hidden;
      &:hover {
        // transform: scale(1.1);
      }
      &-like {
        transition: all 300ms;
        color: grey;
        &:hover {
          color: #113cfc;
        }
      }
      &-liked {
        transition: all 300ms;
        color: #113cfc;
        &:hover {
          color: grey;
        }
      }
      &-reply {
        transition: all 300ms;
        color: grey;
        &:hover {
          color: rgb(0, 0, 0);
        }
      }
      &-thanked {
        transition: all 300ms;
        color: #2faf4f;
        &:hover {
          color: grey;
        }
      }
      &-thank {
        transition: all 300ms;
        color: grey;
        &:hover {
          color: #2faf4f;
        }
      }
      &-follow {
        transition: all 300ms;
        color: grey;
        &:hover {
          color: rgb(172, 165, 66);
        }
      }
      &-following {
        transition: all 300ms;
        color: rgb(172, 165, 66);
        &:hover {
          color: grey;
        }
      }
      &-resolve {
        transition: all 300ms;
        color: grey;
        &:hover {
          color: teal;
        }
      }
      &-resolved {
        transition: all 300ms;
        color: teal;
        &:hover {
          color: grey;
        }
      }
    }
  }
  &__commentReply {
    &__line {
      opacity: 0;
      position: absolute;
      left: -26px;
      top: 0;
      transform: translateY(-50%);
      width: 55px;
      height: 30px;
      border: solid 2px transparent;
      border-style: solid;
      border-width: 2px;
      border-color: transparent transparent rgb(221, 221, 221)
        rgb(221, 221, 221) !important;
      border-radius: 50%/0 0 0 28px;
      z-index: 0;
    }
    &__show {
      display: inline-block;
      width: max-content;
      // padding: 5px;
      // color: blue;
      background-color: transparent;
      // color: $onPrimary;
      color: white;
      cursor: pointer;
      // text-decoration: underline;
      // border-radius: 8px;
      position: relative;
      // padding: 10px;
      padding: 3px 8px;
      transition: all 300ms;
      border: 1px solid white;
      border-radius: 4px;
      &:hover {
        // background-color: $primary;
        border-color: $primary;
        color: $primary;
      }
      &__brand {
        color: white;
        border-color: white;
        &:hover {
          border-color: rgba(black, 0.1);
          color: rgba(black, 0.5);
        }
      }
    }
    &__writeComment {
      &-container {
      }
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      border-radius: 10px;
      margin-top: 5px;
      margin-right: 5px;
      // padding: 5px;
      &__userImage {
        height: 30px;
        margin: 1px;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center top;
        border-radius: 50%;
        background-color: transparent;
      }
      &__input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #f8f8f8;
        padding: 0 15px;
        height: 30px;
        width: 95%;
        margin: 5px 0;
        margin-left: 10px;
        border-radius: 3px;
        & input {
          width: 90%;
          outline: none;
          border: none;
          background-color: transparent;
        }
      }
      &__sendIcon {
        font-size: 20px;
        color: grey;
        transition: all 300ms;
        opacity: 1;
        pointer-events: all;
        // margin: 0 30px;
        cursor: pointer;
        &-hide {
          opacity: 0;
          pointer-events: none;
        }
        &-loader {
          position: static;
          color: rgb(22, 176, 236);
        }
      }
    }
    &__replies {
      &__item {
        margin: 10px 0;
        position: relative;
        display: flex;
      }
    }
  }
  &__comments {
    &__line {
      position: absolute;
      left: -51px;
      top: -25px;
      width: 80px;
      height: 50px;
      border: solid 2px rgb(221, 221, 221);
      border-color: transparent transparent rgb(221, 221, 221)
        rgb(221, 221, 221);
      border-radius: 50%/0 0 0 45px;
      z-index: 0;
    }
    &__writeComment {
      &-container {
      }
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      border-radius: 10px;
      // padding: 5px;
      &__userImage {
        height: 50px;
        margin: 5px;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center top;
        border-radius: 50%;
        background-color: transparent;
      }
      &__input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #e6dfdf;
        padding: 0 15px;
        height: 50px;
        border: 15px;
        width: 100%;
        margin-left: 10px;
        border-radius: 3px;
        & input {
          width: 100%;
          outline: none;
          border: none;
          background-color: transparent;
        }
      }
      &__sendIcon {
        font-size: 20px;
        color: grey;
        transition: all 300ms;
        opacity: 1;
        pointer-events: all;
        // margin: 0 30px;
        cursor: pointer;
        &-hide {
          opacity: 0;
          pointer-events: none;
        }
        &-loader {
          position: static;
          color: rgb(22, 176, 236);
        }
      }
    }
    &__array {
      // position: relative;
      padding: 5px;
      &__item {
        background-color: #8fa9cc;
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        margin: 10px 0;
        padding: 10px 0;
        position: relative;
        &__right {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;
          margin-left: 5px;
        }
        &__left {
          //someting
          display: flex;
          flex-direction: column;
          margin: 0 5px;
          &__lineBox {
            opacity: 0;
            width: 100%;
            flex: 1;
            position: relative;
            &__line {
              position: absolute;
              width: 1px;
              border-left: 2px solid rgb(221, 221, 221);
              height: calc(100%);
              // background-color: rgb(221, 221, 221);
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        &__text {
          color: white;
          padding: 5px 0;
        }
        &__tag {
          &-container {
            position: relative;
          }
          &__verified {
            display: flex;
            position: absolute;
            // top: -50%;
            top: 0;
            right: 0;
          }
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          background-color: #357bce;
          border-radius: 2px;
          padding: 1px 7px;
          color: white;
          font-size: 5px;
        }
        &__upper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &__img {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
          object-fit: cover;
          object-position: center top;
          border-radius: 50%;
          background-color: white;
          border: 1px solid black;
          // margin: 2px 0;
        }
        &__lower {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &__likeCount {
          padding: 5px;
          margin: 0 10px;
        }
        &__button {
          &-liked {
          }
          &-like {
          }
        }
      }
    }
    &__moreComments {
      // position: static;
      & button {
        position: relative;
        background-color: transparent;
        outline: 0;
        border: 0;
        text-decoration: underline;
        cursor: pointer;
        color: rgb(67, 67, 255);
        font-size: 14px;
        padding: 10px;
      }
    }
    &__showComments {
      & button {
        position: relative;
        // background-color: transparent;
        background-color: transparent;
        border-radius: 4px;
        // border: none;
        border: 1px solid rgba(black, 0.2);
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        padding: 8px 12px;
        transition: all 300ms;
        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: $onPrimary;
        }
        // padding-left: 0;
      }
    }
  }
  &__edit {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 25px;
    transition: all 300ms;
    cursor: pointer;
    backface-visibility: hidden;
    // background-color: red;
    height: 35px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    &:hover {
      transform: rotate(30deg);
    }
    @include respond(phone) {
      top: 0;
      right: 0;
    }
  }
}

.brandBackground {
  // background-color: #0ed40ed5;
  background-color: #698db9bb;
  // color: #F8F8F8 !important;
}

.background__color {
  color: #fff;
}

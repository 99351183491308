.dashboard__users__data{
    &__user{
        display: flex;
        flex-direction: column ;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        margin: 10px 0px;
        border-bottom: 1px solid rgb(240, 240, 240);
        &__item{
            flex: 1 1 25%;
        }
        &__intro{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            margin: 20px 0px;
            & img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }  
            & h5{
                
            }
            &__name{
                color: $onSurface-primary;
                font-style: $title;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                text-transform: capitalize;
            }
        }
        &__details{
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            @include respond(phone){
                flex-direction: column;
            }
            &__button{
                & button{
                    background-color: $primary;
                    width: 100px;
                    outline: none;
                    border: none;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 2px;
                    padding: 10px 25px;
                    color: white;
                    transition: all 300ms;
                    position: relative;
                    &:hover{
                        background-color: $primaryHover;
                    }
                    &:active{
                        transform: scale(.98);
                    }
                }
            }
            &__phone, &__email, &__address, &__country-code{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                
                & label{
                    color: $onSurface-primary;
                    font-family: $subTitle;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    @include respond(phone){
                        margin-bottom: 0;
                    }
                }
                & p{
                    font-family: $subTitle2;
                    font-weight: 400;
                    color: $onSurface-secondary;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 30px;
                }
            }

            &__address{
                max-width: 300px;
                text-align: left;
            }
            
        }
    }
}
.dashboard__users__filter-component{
    width: 30%;
    margin-left: auto;
    @include respond(phone){
        margin-left: 0;
        margin-top: 20px;
    }
}
.imageViewer{
    position: fixed;
    inset: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__mask{
        background-color: rgb(0, 0, 0, 0.1);
        position: fixed;
        inset: 0;
        z-index: -1;
    }
    &__close{
        top: 10px;
        right: 10px;
        position: fixed;
        cursor: pointer;
        &__icon{
            font-size: 25px;
            color: white !important;
            border: 1px solid transparent;
            border-radius: 50%;
            padding: 5px;
            width: 35px;
            height: 35px;
            transition: all 300ms;
            &:hover{
                border-color: white;
            }
        }
        // z-index: 1;
    }
    &__image{
        max-width: 80%;
        max-height: 80%;
        inset: 0;
        border-radius: 5px;
        box-shadow: rgba(255, 255, 255, 1) 0px 10px 36px 0px, rgba(255, 255, 255, 1) 0px 0px 0px 1px;
        // z-index: 1;
    }
}
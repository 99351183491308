

.brand__signup__form {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  
    &__inputs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 500px;
      max-width: 90%;
      
     
      &__title {
        margin-bottom: 50px;
        & h1 {
          font-weight: bold;
          font-size: 36px;
          line-height: 110%;
          color: #282c40;
          margin-bottom: 20px;
        }
        &__noaccount-link {
          text-decoration: none;
          color: rgba(40, 44, 64, 0.6);
          margin-bottom: 50px;
        }
      }
      &__username {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        & input {
          width: 100%;
          outline: none;
          border: 0px solid;
          border-bottom: 3px solid #ebebeb;
          padding: 14px 0px;
          &:focus {
            border-color: green;
          }
          
        }
  
        & label {
          font-weight: 500;
          font-size: 12px;
          color: #82869a;
          // margin-left: 14px;
        }
      }
      &__email {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        & input {
          width: 100%;
          outline: none;
          border: 0px solid;
          border-bottom: 3px solid #ebebeb;
          padding: 14px 0px;
          &:focus {
            border-color: green;
          }
        }
        & label {
          font-weight: 500;
          font-size: 12px;
          color: #82869a;
          // margin-left: 14px;
        }
      }
  
      &__password {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
        width: 100%;
        
        & input {
          width: 100%;
          outline: none;
          border: 0px solid;
          border-bottom: 3px solid #ebebeb;
          padding: 14px 0px;
          &:focus {
            border-color: green;
          }
        }
        & label {
          font-weight: 500;
          font-size: 12px;
          color: #82869a;
          // margin-left: 14px;
        }
      }
  
      &__phone {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        & input {
          width: 100%;
          outline: none;
          border: 0px solid;
          border-bottom: 3px solid #ebebeb;
          padding: 14px 0px;
          &:focus {
            border-color: green;
          }
        }
        & label {
          font-weight: 500;
          font-size: 12px;
          color: #82869a;
          // margin-left: 14px;
        }
      }
      &__button {
        width: 100%;
        padding: 25px 0px;
        border: 0px solid;
        border-radius: 10px;
        margin-top: 50px;
        background-color: #357bce;
        color: #ffffff;
        font-weight: 600;
        font-size: 20px;
        box-shadow: rgba(41, 41, 141, 0.25) 0px 2px 5px -1px, rgba(82, 36, 167, 0.3) 0px 1px 3px -1px;   
      }
    }
  }

.signup__form__inputs__username__select{
  width: 100%;
  margin-top: 10px;
  border: 0px solid;
  outline: none;
  
}

.password__warning__validation{
  color: $onSurface-secondary;
}
.brand__comparison {
  display: flex;
  justify-content: stretch;
  margin: auto;
  width: 400px;
  @include respond(phone){
    width: 100%;
    display: block;
  }
  min-height: 500px;
  height: 100%;
  padding: 16px 20px;
  background-color: $background;
  box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  &__logo {
  }
  &__info{
    // background-color: red;
    // flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  &__text {
    font-family: $round;
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    // color: $primary;
    margin: 15px 0px;
    min-height: 100px;
  }
  &__about{
    flex: 1;
    font-family: $subTitle2;
    font-weight: 500;
    max-width: 100%;
    color: $onSurface-secondary;
  }

  &__review {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    & h4{
      color: $onSurface-secondary;
    }
  }
  &__solved-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    &__greater{
        color: #35C9CE;;
    }
    &__smaller{
        color: #FF0000;;
    }
  }
  &__satisfaction-rate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    & h3{
      font-weight: 900;
    }
    & h4{
      font-size: 20px;
      font-weight: 600;
      font-family: $subTitle2;
    }
    &__greater{
        color: #35C9CE;;
    }
    &__smaller{
        color: #FF0000;;
    }
  }
  &__overall-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 12px;
    &__greater{
        color: #35C9CE;;
    }
    &__smaller{
        color: #FF0000;;
    }
  }
}

.dashboard__link{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 1100px;
    margin: auto;
    margin-bottom: 50px;
    // &-container{
    //     display: flex;
    //     flex-direction: row;
    //     background-color: red;
    // }
}

.dashboard__brands__data{
    &__brand{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        margin: 10px 0px;
        padding: 5px 8px;
        border-bottom: 1px solid rgb(109, 109, 109);
        &__premeired{
            background-color: rgba(41, 202, 67, 0.24);
        }
        &__item{
            flex: 1 1 20%;
        }
        &__intro{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 20px 0px;
            &__tick{
                background-color: $primary;
                width: 100px;
                margin-left: 10px;
                color: $onPrimary;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 0px;
                transition: all 300ms;
                cursor: pointer;
                &:hover{
                    background-color: $primaryHover;
                }
            }
            & img{
                max-width: 50px;
                max-height: 50px;
                min-width: 50px;
                min-height: 50px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }  
            &__name{
                color: $onSurface-primary;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                text-transform: capitalize;
            }
        }
        &__details{
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            @include respond(phone){
                flex-direction: column;
            }
            &__button{
                & button{
                    background-color: $primary;
                    width: 150px;
                    outline: none;
                    border: none;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 2px;
                    padding: 10px 25px;
                    color: white;
                    transition: all 300ms;
                    position: relative;
                    margin: 10px;
                    &:active{
                        transform: scale(.98);
                    }
                    &:hover{
                        background-color: $primaryHover;
                    }
                }
                &__button2{
                    margin-top: 10px;
                }
            }
            &__phone, &__email, &__address, &__country-code{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                
                & label{
                    color: $onSurface-primary;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
                & p{
                    color: $onSurface-secondary;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 30px;
                }
            }

            &__address{
                max-width: 300px;
                text-align: left;
            }
            
        }
    }
    &__brands{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.dashboard__brands__filter{
    margin: 20px 0px;
    width: 30%;
    margin-left: auto;
    @include respond(phone){
        width: 100%;
    }
}
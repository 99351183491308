.mydetails{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;  
    position: relative;
    &__details{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
    }
    &__update-details{
        // background-color: red;
        & h1{
            align-self: center;
            inset: 0;
            // background-color: red;
        }
        &__mask{
            background-color: rgba(0, 0, 0, 0.247);
            position: fixed;
            inset: 0;
        }
        &__update{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 100;
        padding: 14px 18px;
        background-color: $onPrimary;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        border-radius: 10px;
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 500px;
        // height: 500px;
        max-width: 90%;
        transition: 1.5;
        // background-color: red;
        position: relative;
        @include respond (phone){
            left: 43%;
            width: 400px;
        }

        &__cancel{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
            cursor: pointer;
            @include respond(phone){
                left: 50%;
            }
            &:hover{
                background-color: $primaryHover;
            }
            &-icon{
                fill: $onPrimary;
            }
        }
        &__phone{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
            & label{
                font-weight: 500;
            }
            &__phone-number{
                // width: 10%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                // background-color: red;
            }
            
        }
         &__dob, &__address, &__country, &__email{
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: flex-start;
                inset: 0;
                
                & input{
                    width: 100%;
                    border: 0px solid;
                    border-bottom: 1px solid gray;
                    padding: 10px 14px;
                    margin: 20px 0px;
                    &:focus{
                        outline: none;
                    }
                }
                & label{
                    font-weight: 500;
                }
            }
            &__dob{
                width: 100%;
                &__container{
                    width: 100%;
                }
                
            }

            &__address{
                & textarea{
                    width: 100%;
                    border: 0px solid;
                    border-bottom: 1px solid gray;
                    padding: 10px 14px;
                    margin: 20px 0px;
                    outline: none;
                    resize: none;
                    &::placeholder{
                        font-family: Arial, Helvetica, sans-serif;
                    }
                }
            }
            &__button{
                width: 100%;
                padding: 12px 16px;
                background-color: $primary;
                text-align: center;
                border-radius: 8px;
                position: relative;
                &:hover{
                    cursor: pointer;
                    background-color: $primaryHover;
                }
                & h1{
                    color: $onPrimary;
                }
            }
    }
    }
}
.mydetails__update-button{
    width: 100%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @include respond(phone){
        flex-direction: column;
        justify-content: center;
    }
    &__button1{
        min-width: 30%;
        @include respond(phone){
            margin-bottom: 20px;

        }  
    }
    &__button2{
        min-width: 30%;
    }
}
.PhoneInput{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    // background-color: red;
    justify-content: flex-start;
    // margin-bottom: 0;
    // margin-left: 10px;
}

.PhoneInputCountryIcon{
    // display: none;
    position: absolute;
    margin-top: 10px;
    top: -10px;
    width: 53px;
    height: 50px;
}
.PhoneInputCountry{
    position: relative;
    // background-color: red;
}
.PhoneInputCountrySelect{
    // display: none;
    outline: none;
    border: 0px solid;
    width: 70px;
    margin-top: 10px;
}
.PhoneInputInput{
    width: 100%;
    // background-color: red;
    outline: none;
    border: 0px solid;
    border-bottom: 1px solid gray;
    padding: 5px 9px;
    // margin: 20px 0px;
}
.mydetails {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  max-width: 1000px;
  padding: 10px 14px;
}

/* Tag component styling */
.tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 400px;
  margin-bottom: 50px;
}
.tag label {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
}

.tag__address {
  width: 100%;
  text-align: left;
}
.tag__address label {
  margin-right: 20px;
}

.detail__tag__phone {
  background-color: transparent;
  outline: none;
  border: none;
  position: relative;
  cursor: default;
  &:after {
    position: absolute;
    content: "no phone";
    top: -120%;
    left: 50%;
    transform: translateX(-50%, 50%);
    height: 100%;
    width: fit-content;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    background-color: $primary;
    color: $onPrimary;
    display: none;
    white-space: nowrap;
    padding: 5px 9px;
  }
  &-verified {
    color: green;
    &:hover {
      &::after {
        display: block;
      }
    }
    &::after {
      content: "Telefon Numarası Doğrulandı";
    }
  }
  &-unverified {
    color: red;
    &:hover {
      &::after {
        display: block;
      }
    }
    &::after {
      content: "Telefon Numarası Doğrulanmadı";
    }
  }
}

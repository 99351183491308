.brand__main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__brand-content{
        padding: 8px 12px;
        width: 1100px;
        max-width: 90%;
        background-color: $background;
        box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        margin: auto;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    &__brand-details{
        margin-bottom: 50px;
        max-width: $maxwidth;
        width: 100%;
    }
}

.brand__background{
    &__blue-spiral{
        position: absolute;
        z-index: -10;
        top: 0;
        right: 0;
        @include respond(phone){
            display: none;
        }
    }
    &__yellow-spiral{
        position: absolute;
        z-index: -10;
        top: 20px;
        left: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__horizantal-dot{
        position: absolute;
        z-index: -10;
        top: 240px;
        right: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__vertical-dot{
        position: absolute;
        z-index: -10;
        bottom: 0;
        left: 20%;
        transform: translate(50%, 70%);
        @include respond(phone){
            display: none;
        }
    }
    &__vertical-dot2{
        position: absolute;
        z-index: -10;
        top: 300px;
        left: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__yellow-zigzag{
        position: absolute;
        z-index: -10;
        bottom: 0;
        left: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__blue-zigzag{
        position: absolute;
        z-index: -10;
        bottom: 0;
        right: 5%;
        @include respond(phone){
            display: none;
        }
    }
}

.empty__data__container{
    width: 500px;
    max-width: 100%;
    margin: auto;
}

  .phone__verification__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 500px;
    max-width: 90%;
    &__title {
      margin-bottom: 50px;
      & h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 110%;
        color: #282c40;
        margin-bottom: $onSurface-primary;
      }
    }
    &__otp {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      & input {
        width: 100%;
        outline: none;
        border: 0px solid;
        border-bottom: 3px solid #ebebeb;
        padding: 14px 0px;
        &:focus {
          border-color: green;
        }
      } 
  }
  &__button {
    width: 100%;
    padding: 25px 0px;
    border: 0px solid;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    background-color: $primary;
    color: $onPrimary;
    font-weight: 600;
    font-size: 20px;
    box-shadow: rgba(41, 41, 141, 0.25) 0px 2px 5px -1px,
      rgba(82, 36, 167, 0.3) 0px 1px 3px -1px;
  }
}
  
.image__thumbnail{
    margin-right: 10px;
    position: relative;
    // background-color: red;
    display: flex;
    align-items: center;
    // width: 50px;
    height: 50px;
    max-height: 45px;
    cursor: pointer;

    transition: all 300ms;
    backface-visibility: hidden;
    transform: translateZ(0);
    &__image{
        // width: 100%;
        // width: auto;
        // height: auto;
        max-height: 50px;
        object-fit: contain;
        border-radius: 5px;
    }
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        transform: translateZ(0) translateY(-5px);
    }
}
.comparison__list{
    display: inline-block;
    // background-color: red;
    position: absolute;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    top:100%;
    width: 100%;
    background-color: #fff;
    // backdrop-filter: blur(5px);
    max-height: 300px;
    overflow-y: auto;   
    color: #272727;
    z-index: 75;
    &__item{
        &:not(:last-child){
            border-bottom: 1px solid #f0f0f0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:15px 15px;
        cursor: pointer;
        transition: 300ms;
        text-decoration: none;
        color: inherit;
        & img{
            max-width: 50px;
            max-height: 50px;
            min-width: 50px;
            min-height: 50px;
            // border-radius: 50%;
            object-fit: contain;
        }
        & h3{
            font-family: $subTitle2;
            font-size: 15px;
            font-weight: 700;
            text-transform: capitalize;
            margin-left: 15px;
        }
        &:hover{
            background-color: ghostwhite;
        }
    }
}
.dashboard{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    &__add-category{
        margin-top: 20px;
        text-decoration: underline;
        color: blue;
        cursor: pointer;
        transition: all 300ms;
        &__button{
            width: 30%;
            background-color: $primary;
            color: $onPrimary;
            padding: 15px 0px;
            border: none;
            margin-top: 10px;
            border-radius: 8px;
            &:hover{
                background-color: $primaryHover;
            }
        }
        &__inputs{
            margin-top: 20px;
            width: 100%;
            & label{
                color: $onSurface-primary;
            }
            & input{
                width: 100%;
                border: none;
                border-bottom: 1px solid rgba($onSurface-secondary, 0.1);
                padding: 10px 0px;
                &:focus{
                    outline: none;
                    border-bottom: 3px solid green;
                }
            }
        }
    }
    &__panel__review{
        max-width: 1100px;
        margin: 0px auto;
        max-width: 90%;
    }

    &__panel__chart{
        width: 100%;
        max-width: 100%;
    }

    &__panel__user{
        width: 1100px;
        max-width: 90%;
        margin: auto;
        // padding: 10px 14px;
        // margin-bottom: 50px;
    }

    &__panel__add-brand{
        width: 100%;
        text-align: center;
    }

    &__panel__reports{
        width: 100%;
        padding-bottom: 50px;
        // position: relative;
        overflow: auto;
        &__images{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 10px 0;
            &__item{
                margin: 5px 0;
            }
        }
        &__table{
            width: 100%;
            border-collapse: collapse;
            // background-color: red;
            
            & tr{
                & th{
                    text-align: left;
                    font-family: $title;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 30px;
                    color: $onSurface-primary;
                    vertical-align: top;
                    min-width: 200px;
                    @include respond(phone){
                        font-size: 15px;
                        line-height: 15px;
                    }
                }
                &:not(:first-child):hover{
                    background-color: ghostwhite;
                }
            }
            &__data-rows{
                border-bottom: 1px solid rgba($onSurface-secondary, 0.1);  
                & td{
                    padding: 20px 0px; 
                    color: $onSurface-secondary;
                    font-family: $subTitle;
                    vertical-align: top;
                    cursor: pointer;
                    @include respond(phone){
                        text-align:left;
                    }
                }
                &__name{
                    text-transform: capitalize;
                    max-width: 100px;
                }
                &__ratings{
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    & h4{
                        margin-right: 10px;
                        margin-bottom: 4px;
                    }
                    &__stars{
                        display: flex;
                        flex-direction: row;  
                        align-items: center;
                    }
                }
            }
            &__comment{
                max-width: 200px;
            }
        }
    }
}
.dashboard__links{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 50px;

    height: 50px;
    @include respond(phone){
        flex-direction: column;
        height: auto;
    }
    &-container{
        position: relative;
        width: 45%;
        min-width: 45%;
        background-color: #fff;
        padding: 0px 10px;
        border-radius: 4px;
        @include respond(phone){
            width: 100%;
        }
    }
    &__filters{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 55%;
        min-width: 55%;
        @include respond(phone){
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
        }
        &__item{
            margin-right: 10px;
            @include respond(phone){
                margin: 10px 0;
            }
        }
    }
}
.dashboard__links__header{
    display: none;
    align-self: center;
    @include respond(phone){
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 14px;
        max-width: 100%;
        background-color: $surface;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        & h1{
            color: $onSurface-primary;
        }
    }
    
}
.dashboard__links__list{
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    justify-content: space-between;
    align-items: center;
    z-index: 75;
    position: relative;
    height: 50px;
    &::after{
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        bottom: 3px;

        z-index: -1;
    }
    @include respond(phone){
        display: none;
        flex-direction: column;  
        width: 100%;
        position: absolute;
        // z-index: 2;
        background-color: $surface;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        // background-color: red;
        height: auto;
        &::after{
            display: none;
        }
    }
}
.dashboard__links__show{
    display: flex;
}
.dashboard__links__hide{
    @include respond(phone){
        display: none;
    }
}
.dashboard__links ul li{
    display: inline-block;
    list-style: none;
    text-align: center;
    cursor: pointer;
    padding: 10px 0px;
    font-family: $subTitle;
    font-weight: 700;

    @include respond(phone){
        display: block;
        padding: 10px 0px;
        width: 100%;
        margin: 0px 0px;
    }
} 

.dashboard__list__click{
    border-bottom: 5px solid $primary;
    color: $primary;
}

.dashboard__filter__input{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            padding: 12px 15px;
            flex: 0.5;  
            &__filter{
                flex: 0.5;
                margin-right: 10px;
                @include respond(phone){
                    width: 100%;
                    flex: none;
                    margin-bottom: 10px;
                }
            }
            &__picker{
                flex: 0.5; 
                @include respond(phone){
                    flex: none;
                    width: 100%;
                } 
            }
            @include respond(phone){
                flex: none;
                width: 100%;
            }
}

.dashboard__filter__input input{
                flex: 1;
                border: 0px;
                background-color: transparent;
                outline: none;
}

.dashboard__filter__input input:focus{
    outline: none;
}

.admin__dashboard__brands{
    width: 1100px;
    max-width: 98%;
    margin: auto;
    background-color: red;
    &__title{
        display: flex;
        flex-direction: row;
        align-items: center;
        &__image{
            max-height: 50px;
            min-height: 50px;
        }
    }
}

.dashboard1__settings{
}

@media(max-width: 700px){
    .dashboard{
        justify-content: center;
    }
}

.delete__category{
    background-color: $background;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: fixed;
    width: 500px;
    max-width: 100%;
    top: 30%;
    overflow-y: auto;
    min-height: 350px;
    max-height: 350px;
    left: 33%;
    border-radius: 8px;
    z-index: 75;
    @include respond(phone){
        left: 0;
    }
    &__mask{
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: fixed;
        inset: 0;
    }
    &__list{
        display: flex;
        flex-flow: row wrap;
        padding: 20px;
        position:relative;
        &__item{
            background-color: $primary;
            color: $onPrimary;
            border-radius: 5px;
            padding: 5px 7px;
            margin-bottom: 10px;
            margin-right: 10px;
            position: relative;
            cursor: pointer;
            &__cross{
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.8);
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                right: 0;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                transform: translate(50%, -50%);
                cursor: pointer;
                transition: all 300ms;
                &:hover{
                    transform: translate(50%, -50%) scale(1.4);
                }
                &__icon{
                    fill: $background;
                    font-size: 10px;
                }
            }
        }
    }
}
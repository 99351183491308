// @mixin respond($breakpoint) {
//     @if $breakpoint == tab {
//       @media (max-width: 900px) {
//         @content;
//       } //900px
//     }
//     @if $breakpoint == phone {
//       @media (max-width: 700px) {
//         @content;
//       } //768px
//     }
//     @if $breakpoint == big-desktop {
//       @media (min-width: 87.5em) {
//         @content;
//       } //1400px
//     }
// }

.registration__form__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 24px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 400px;
  min-width: 350px;
  background-color: #ffffff;
  @include respond(phone) {
    display: none;
  }
  &__main {
    @include respond(phone) {
      width: 100px;
    }
  }
  &__topleft {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    object-fit: cover;
    &__div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 80px;
      height: 80px;
      background-color: $background;
    }
    @include respond(phone) {
      width: 100px;
    }
  }
  &__topright {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    object-fit: cover;
    &__div {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(50%) translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 80px;
      height: 80px;
      background-color: $background;
    }
    @include respond(phone) {
      width: 100px;
    }
  }
  &__bottomleft {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    object-fit: cover;
    &__div {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%) translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 80px;
      height: 80px;
      background-color: $background;
    }
    @include respond(phone) {
      width: 100px;
    }
  }
  &__bottomright {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    object-fit: cover;
    &__div {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(50%) translateX(50%);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 80px;
      height: 80px;
      background-color: $background;
    }
    @include respond(phone) {
      width: 100px;
    }
  }
}
@media (max-width: 1100px) {
  .registration__form__icons {
    display: none;
  }
}

.brand__chart__container{
    width: 100%;
    &__date-picker{
        width: 50%;
        margin: 20px auto;
        @include respond(phone){
            margin: 20px 0;
            width: 100%;
        }
        @include respond(tab){
            margin: 20px 0;
            width: 100%;
        }
    }
}
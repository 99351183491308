.update__brand__password{
    text-align: center;
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
    margin-top: 20px;
    &__container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        &__button{
            margin: 10px auto;
            width: 200px;
            height: 60px;
            background-color: $primary;
            color: $onPrimary;
            border-radius: 8px;
            border: none;
            transition: all 300ms;
            font-size: 18px;
            &:hover{
                background-color: $primaryHover;
            }
            
        }
        & p{
            font-family: $round;
            font-size: 20px;
            margin: 40px auto;
            // margin-bottom: 40px;

        }
        &__inputs{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            &__old-pass, &__new-pass, &__confirm-pass{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 20px;
                & input{
                    width: 100%;
                    padding: 10px 0px;
                    border: none;
                    outline: none;
                    border-bottom: 1px solid $onSurface-secondary;
                    &:focus{
                        border-bottom: 2px solid green; 
                    }
                    
                }
            }
        }
    }
}
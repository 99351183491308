.brand__login{
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: center;
    justify-content: space-around;
    margin: 50px 0px;
    position: relative;
    &__component{
        @include respond(phone){
            display: none;
        }
    }
}     
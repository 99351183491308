.imagePreview{
    position: relative;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    margin: 10px 10px;
    &__image{
        max-height: 100px;
        max-width: 100px;
        min-height: 100px;
        min-width: 100px;
        width: 100%;
        object-fit: contain;
    }
    &__close{
        // background-color: grey;
        background-color: rgb(236, 236, 236);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 5px;
        border-radius: 50%;
        position: absolute;
        // top: 10px;
        // right: 10px;
        top: 0;
        right: 0;
        cursor: pointer;
        transform: translate(50%, -50%);
        &:hover{
            transform: translate(50%, -50%) scale(1.05);
        }
    }
}
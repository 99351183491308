.update__brand{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: $onSurface-secondary;
    z-index: 100;
    @include respond(phone){ 
    }
    &__profile{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        width: 500px;
        
        padding: 30px 10px;
        background-color: $background;
        border-radius: 10px;
        position: relative;
        @include respond(phone){
            width: 350px;
        }

        &__email{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
            & label{
                
            }
            & input{
                width: 100%;
                border: none;
                padding: 10px 0px;
                border-bottom: 1px solid $onSurface-secondary;
                outline: none;
                &:focus{
                    border-bottom: 3px solid green;
                }
            }
        }
        &__category{
            & label{
            }
        }
        &__inputs{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px;
            &__phone{
                margin-bottom: 20px;
                width: 100%;
                &-input{
                    & .PhoneInputInput{
                        padding: 10px 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                        margin: 10px 0;
                    }
                    & .PhoneInputCountrySelect{
                        & option{
                            margin: 10px;
                            height: 100px;
                            line-height: 30px;
                            font-size: 14px;
                        }
                    }
                    width: 100%;
                }
            }
            &__about{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 10px;
                &__input{
                    resize: none;
                    width: 100%;
                    height: 150px;
                    outline: none;
                    border: 0px solid;
                    padding: 10px 6px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                }
            }
            &__submit-button{
                margin-top: 20px;
                width: 100%;
                background-color: $primary;
                text-align: center;
                color: white;
                padding: 10px;
                border-radius: 10px;
                cursor: pointer;
                transition: 300ms;
                & h1{
                    font-weight: 600;
                    font-size: 18px;
                    text-transform: uppercase;
                }
                &:hover{
                    transform: scale(1.01);
                }
            }
            &__cancel-button{
                background-color: $primary;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -50%);
                transition: all 300ms;
                &:hover{
                    background-color: $primaryHover;
                }
                &-icon{
                    color: white;
                    backface-visibility: hidden;
                    transform: translateZ(0);
                }
            }
        }
    }
}
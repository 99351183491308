.dashboard__review__component{
    // background-color: red;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond(phone){
        margin: 80px auto
    }
    &__container{
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        margin: 20px 20px;
    }
    &__item{
        margin-left: 50px;
        @include respond(phone){
            margin-bottom: 10px;
        }
    }
}

.dashboard__panel__reports__button{
    & button{
        outline: none;
        border: none;
        background-color: $primary;
        padding: 10px 25px;
        border-radius: 3px;
        transition: all 300ms;
        cursor: pointer;
        color: white;
        position: relative;
        width: 100px;
        &:hover{
            background-color: $primaryHover;
        }
        &:active{
            transform: scale(0.98);
        }
    }
}

.admin__dashboard__name-tag{
    text-transform: capitalize;
}
.no__data__container{
    width: 500px;
    max-width: 100%;
   
}
.dashboard__panel__reports__table__comment{
    // background-color: red;
    overflow-wrap: break-word;
    word-wrap: break-word;
     hyphens: auto;
}
.dashboard__panel__reports__table__data-rows__name{
    &__text{
    }
    &__rating{
        color: goldenrod;
        font-size: 20px;
        &__icon{
            color: gold;
        }
    }
}
.dashboard__panel__reports__table__data-rows__brand-name{
    &__text{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        
    }
}
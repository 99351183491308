.notification {
  //   position: relative;
  display: flex;
  height: 50px;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  &-container {
    z-index: 1000;
    position: fixed;
    margin: 1%;
    // min-width: 200px;
    right: 0;
    // width: max-content;
    color: white;
    text-align: center;
    border-radius: 5px;
    top: 70px;
  }
  &__close {
    opacity: 0.5;
    border-radius: 4px;
    background-color: white;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    transition: all 300ms;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 20px;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
  &__text {
    padding-left: 10px;
  }
  //   & p {
  //     font-family: $round;
  //     font-family: 100;
  //     text-transform: capitalize;
  //   }
}

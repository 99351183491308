// @mixin respond($breakpoint) {
//     // @if $breakpoint == tab-land {
//     //   @media (max-width: 75em) {
//     //     @content;
//     //   }
//     // } //1200px
//     @if $breakpoint == tab {
//       @media (max-width: 900px) {
//         @content;
//       } //900px
//     }
//     @if $breakpoint == phone {
//       @media (max-width: 700px) {
//         @content;
//       } //768px
//     }
//     @if $breakpoint == big-desktop {
//       @media (min-width: 87.5em) {
//         @content;
//       } //1400px
//     }
//   }

.compare__text {
  max-width: 90%;
  margin: auto;
  @include respond(phone) {
    margin-top: 30px;
  }

  & h1 {
    font-family: $round;
    font-weight: 700;
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
  }
  & p {
    font-family: $subTitle;
    font-weight: 500;
    max-width: 600px;
    color: $onSurface-secondary;
    line-height: 30px;
  }
}

.homepage__header{
    // &-container{
    //     position: relative;
    //     top: -80px;
    //     @include respond(phone){
    //         top: -30px;
    //     }
    // }
    display: flex;
    flex-direction: row;
    @include respond(phone){
        flex-direction: column;
    }
    &__search{
        display: none;
        position: relative;
        @include respond(phone){
            display: block;
            margin: 0 10px;
            margin-top: 30px;
        }
    }
    &__left{
        // background-color: #E8FFFE;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px;
        @include respond(phone){
            width: 100%;
        }
        &__sub{
            width: 500px;
            @include respond(phone){
                width: 100%;
            }
            margin: auto;
        }
    }
    &__infoHeading{
        font-family: $round;
        font-weight: 700;
        margin-bottom: 50px;
        font-size: 50px;
        // font-size: 900;
    }
    &__infoText{
        font-family: $subTitle;
        margin-bottom: 50px;
        color: $onSurface-secondary;
        font-size: 20px;
    }
    &__link{
        border: none;
        outline: none;
        background-color: $primary;
        border-radius: 8px;
        padding: 20px 30px;
        font-size: 20px;
        cursor: pointer;
        color: white !important;
        transition: all 300ms;
        &:hover{
            background-color: $primaryHover;
        }
    }
    &__right{
        width: 50%;
        margin-top: 50px;
        @include respond(phone){
            margin-top: 100px;
            width: 100%;
        }
    }
    overflow: hidden;
    padding-bottom: 20px;
    &__brand{
        display: flex;
        align-items: center;
        transform: rotate(30deg) translate(50px, 50px);
        @include respond(phone){
            transform: none;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-evenly;
        }
        &__col1{
            display: flex;
            flex-direction: column;
            margin:20px;
            @include respond(phone){
                flex-direction: row;
                justify-content: space-around;
                width: auto;
                margin: 0;
                width: 66.66%;
            }
        }
        &__col2{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 90%;
            @include respond(phone){
                flex-direction: row;
                justify-content: space-around;
                width: 33.33%;
            }
        }
        &__item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px 20px;
            margin: 20px;
            box-shadow: 0px 30px 80px rgba(13, 19, 19, 0.1);
            background-color: white;
            border-radius: 10px;
            aspect-ratio: 1;
            width: 200px;
            @include respond(phone){
                width: 100px;
                height: 100px;
                padding: 10px;
                margin: 10px 0px;
                text-align: center;
                margin: 0;
            }
            &__logo{
                max-width: 120px;
                border-radius: 8px;
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }
            &__logo-container{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                height: 120px;
                transition: 300ms;
                @include respond(phone){
                    width: 50px;
                    height: 50px;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
            &__rating{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 20px 0;
                @include respond(phone){
                    flex-direction: column;
                    margin: 0;
                    margin-top: 5px;
                }
                & p{
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 8px;
                    font-weight:600;
                    @include respond(phone){
                        font-size: 8px;
                    }
                }
            }
        }
    }
}
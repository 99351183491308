.brandMain {
  margin: auto;
  &__reviews-container {
    // max-width: 1000px;
    // margin: auto;
    // flex: 90%;
    width: 100%;
    flex: 1;
    min-width: 600px;

    @include respond(phone) {
      min-width: auto;
    }
  }
  &__reviews {
    position: relative;
    // width: 100%;
    // background-color: red;
    min-height: 100px;
  }
  &__writeReview {
    max-width: 1000px;
    margin: auto;
  }
  &__lower {
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    padding: 15px 0 50px 0;
    border-radius: 10px;
    // background-color: red;
    // @include respond(phone) {
    //   flex-direction: column;
    // }
  }
  &__right {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 400px;
    // background-color: red;
    margin-left: 20px;
    @include respond(port) {
      flex-direction: row;
      width: 100%;
      margin-left: 0;
      align-items: stretch;
    }
    @include respond(phone) {
      margin-left: 0;
      margin: auto;
      flex-direction: column;
    }
    &__compare {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.07);
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      @include respond(port) {
        margin-top: 0;
        margin-left: 10px;
      }
      @include respond(phone) {
        margin-left: 0;
      }
    }
  }
  &__topbrands {
    background-color: $background;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.07);
    // margin: 10px;
    @include respond(phone) {
      margin: 10px auto;
    }
    & h2 {
      padding: 20px 10px;
      font-size: 19px;
      color: $primary;
    }
  }
  &__showAllButton {
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
  &__background {
    &__vertical-dot {
      position: absolute;
      top: 200px;
      left: 5%;
      z-index: -10;
      @include respond(phone) {
        display: none;
      }
    }
    &__vertical-dot2 {
      position: absolute;
      bottom: 0px;
      left: 20%;
      z-index: -10;
      transform: translate(50%, 70%);
      @include respond(phone) {
        display: none;
      }
    }
    &__horizantal-dot {
      position: absolute;
      top: 250px;
      right: 5%;
      z-index: -10;
      @include respond(phone) {
        display: none;
      }
    }
    &__blue-spiral {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -10;
      @include respond(phone) {
        display: none;
      }
    }
    &__yellow-spiral {
      position: absolute;
      bottom: 0;
      left: 5%;
      z-index: -10;
      @include respond(phone) {
        display: none;
      }
    }
    &__yello-zigzag {
      position: absolute;
      bottom: 0;
      right: 5%;
      z-index: -10;
      @include respond(phone) {
        display: none;
      }
    }
  }
}

/* Footer intro */
.footer {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  width: 100%;
}
.footer__content {
  padding-top: 140px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  border-bottom: 1px solid #dddddd;
}
.footer__content__intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.footer__content__intro img {
  width: 50px;
  height: 50px;
}
.footer__content__intro div {
  // background-color: red;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}
.footer__content__intro div h4 {
  // color: $primary;
  // background-color: red;
  font-size: 20px;
  font-weight: 600;
  margin: 0 10px;
  margin-top: 1.5px;
}
.footer__content__intro p {
  max-width: 250px;
}
.footer__content__links__title {
  text-decoration: none;
  & h4 {
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $primary;
  }
  /* identical to box height */

  color: #000000;
}

.footer__content__social__links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  color: $primary;
  &-facebook,
  &-twitter,
  &-instagram {
    cursor: pointer;
    &__icon {
      color: $primary;
      font-size: 30px;
    }
  }
}

/* Footer Rights */
.footer__rights {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  padding: 20px 50px;
}
.footer__rights__link {
  & h4 {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
  }
}
.footer__rights p {
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 700px) {
  .footer__content__intro {
    margin-bottom: 30px;
  }
}

.panel__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 30px 40px;
    max-width: 1100px;
    box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: $background;
}

.panel__div__intro{
    display: block;
    margin-bottom: 20px;
}

.panel__div__intro h1{
    font-family: $round;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: rgba(0, 0, 0, 0.8);
}
.panel__div__intro p{
    font-family: $subTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;

    color: rgba(0, 0, 0, 0.5);
    max-width: 400px;
}

.panel__div__details{
    display: inline-block;
    text-align: left;
}
.panel__div__details p{
    font-family: $subTitle;
    margin-top: 20px;
    max-width: 500px;
    color: rgba(0, 0, 0, 0.5);
}

.panel__div__details__initials{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.panel__div__details__initials div{
    /* display: inline-block;
    text-align: center;
    margin-right: 60px; */   
}

.panel__div__details__initials div h1, .panel__div__details__initials div h3{
    font-family: $title;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: $primary;
}

.admin{
    position: relative;
    &__panel{
    }
    &__dashboard{
        width: 1100px;
        max-width: 100%;
        margin: auto;
    }
    &__background__horizantal-dots{
        position: absolute;
        z-index: -10;
        top: 20%;
        left: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__background__horizantal-dots2{
        position: absolute;
        z-index: -10;
        bottom: 0%;
        left: 30%;
        transform: translate(50%, 70%);
        @include respond(phone){
            display: none;
        }
    }
    &__blue-spiral{
        position: absolute;
        z-index: -10;
        top: 0;
        right: 0;
        @include respond(phone){
            display: none;
        }
    }
    &__yello-zigzag{
        position: absolute;
        z-index: -10;
        bottom: 0;
        left: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__dots{
        position: absolute;
        z-index: -10;
        top: 50%;
        right: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__blue-zigzag{
        position: absolute;
        z-index: -10;
        bottom: 0;
        right: 5%;
        @include respond(phone){
            display: none;
        }
    }
    &__dots2{
        position: absolute;
        z-index: -10;
        left: 6%;
        top: 0;
        @include respond(phone){
            display: none;
        }
    }
}

@media(max-width: 700px){
    .panel__div{
        justify-content: center;
    }
}

.review{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px auto;
    padding: 10px;
    @include respond(phone){
        width: 100%;
        display: block;
    }
    // background-color: red;
    // z-index: 2;
    & input, & textarea{
        outline: none;
    }
}
.review-container{
    // background-color: red;
    position: relative;
    &__blue-spiral{
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @include respond(phone){
            display: none;
        }
    }
    &__zigzag{
        position: absolute;
        bottom: 0;
        right: 16%;
        z-index: -1;
        @include respond(phone){
            display: none;
        }
    }
    &__zigzag2{
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: -1;
        @include respond(phone){
            display: none;
        }
    }
    &__zigzag3{
        position: absolute;
        top: 50%;
        left: 5%;
        z-index: -1;
        @include respond(phone){
            display: none;
        }
    }
    &__dot{
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        @include respond(phone){
            display: none;
        }
    }
}
.review__img{
    width: 500px;
    max-width: 90%;
    @include respond(phone){
        display: none;
    }
}

.review__content{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.review__content__dropdown{
    width: 100%;
    background-color: #F8F8F8;
    padding: 10px 12px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 0px solid;
    & option{
        background-color: #F8F8F8;
    }
}

.review__content__upload{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    max-width: 300px;
}

.review__content__previewImg__img{
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
}
.review__content__previewImg__img:hover{
    transform: scale(1.1);
}


@media(max-width: 700px){
    .review__content__previewImg__zoom img{
        width: 400px;
    }
    .review__content__previewImg__zoom{
        align-items: center;
    }
}

.review__content__previewImg__zoom__close-icon{
    background-color: blue;
}

.review__content__tboxes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    @include respond (phone){
        width: 100%;
    }
    // max-width: 90%;
    
}

.review__content__textbox, .review__content__textarea{
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 10px 12px;
    background-color: #F8F8F8;
    border: 0px solid;
    box-sizing: border-box;
}  
.review__content__textarea{
    resize: none;
}   



.review__content__buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.review__content__uploadButton {
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    height: 60px;
    width: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $subTitle;
}

.review__content__publishButton {
    border-radius: 10px;
    font-weight: 500px;
    font-size: 18px;
    border: 0px solid;
    cursor: pointer;
    transition: 100ms;
    height: 60px;
    width: 140px;
    &:hover{
        border-color: $primary;
        color: $primary;
        background-color: transparent;
    }
}


.review__content__uploadButton{

    background-color: #F8F8F8;
    // border: 2px solid $primary;
    cursor: pointer;
    transition: 100ms;
    border: 1px solid transparent;
    & label{
        cursor: inherit;
    }
}


.review__content__uploadButton:hover{
    background-color: $primary;
    color: $onPrimary;
    // border: 2px solid $primary;
}

.review__content__uploadButton input{
    display: none;
}
.review__content__publishButton{
    background-color: $primary;
    color: $onPrimary;
    margin-left: 10px;
    position: relative;
    border: 1px solid transparent;
    
}

.review__star__container{
    width: 100%;
    background-color: $surface;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 5px 5px;
    &__stars{
        display: flex;
        flex-direction: column;
        justify-content: center;   
        align-items: flex-start;
        & label{
            font-size: 15px;
            font-weight: bold;
            align-self: center;
            color: rgba(0, 0, 0, 0.425);
        }
        &__selector{
            align-self: center;
        }
    }
}

.review__content__tboxes1{
    // background-color: red;
    width: 100%;
    position: relative;
    // background-color: red;
    &__input{
        margin-bottom: 20px;
        width: 100%;
        border-radius: 8px;
        padding: 10px 12px;
        background-color: #F8F8F8;
        border: 0px solid;
        box-sizing: border-box;
    }

    &__search-list__list{
        display: inline-block;
        position: absolute;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        top:100%;
        left: 0;
        // right: 1%;
        width: 100%;
        background-color: #fff;
        max-height: 300px;
        overflow-y: auto;   
        color: #272727;
        z-index: 75;
        &__item{
            &:not(:last-child){
                border-bottom: 1px solid rgb(231, 231, 231);
            }
            display: flex;
            flex-direction: row;
            align-items: center;
            padding:15px 10px;
            cursor: pointer;
            transition: 300ms;
            text-decoration: none;
            color: inherit;
            height: 75px;
            & img{
                max-width: 50px;
                max-height: 50px;
                margin-right: 5px;
                object-fit: contain;
                // background-color: red;
                border-radius: 4px;
            }
            & h3{
                text-transform: capitalize;
            }
            &:hover{
                background-color: ghostwhite;
            }
        }
    }
}
.comparison {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
  &__button {
    background-color: $primary;
    padding: 12px 16px;
    border-radius: 8px;
    margin-top: 20px;
    width: 50%;
    @include respond(phone) {
      width: 100%;
    }
    text-align: center;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.05);
    }
    & p {
      color: $onPrimary;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__first-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    width: 300px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 8px;

    & input {
      border: 0px solid;
      outline: none;
      width: 100%;
      padding: 12px 16px;
      background-color: transparent;
      color: $onSurface-secondary;
      text-transform: capitalize;
      &::placeholder {
        color: $onSurface-secondary;
      }
    }
  }
  & h1 {
    color: #357bce;
    align-self: flex-start;
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  &__second-brand {
    background: #f8f8f8;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    position: relative;
    border-radius: 8px;
    & input {
      border: 0px solid;
      outline: none;
      width: 100%;
      padding: 12px 16px;
      background-color: transparent;
      color: $onSurface-secondary;
      text-transform: capitalize;
      &::placeholder {
        color: $onSurface-secondary;
      }
    }
  }
}

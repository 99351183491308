.topbrands {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
  background-color: $background;
  border-radius: 5px;
  @include respond(phone) {
    margin: 0 10px;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;

    font-size: 15px;
    & td {
      padding: 10px 0px;
      // background-color: red;
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 7px 10px;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(224, 224, 224, 0.541);
    }
    transition: all 300ms;
    &:hover {
      background-color: ghostwhite;
    }
    @include respond(phone) {
      margin: 0 10px;
    }
    &__position {
      position: absolute;
      left: 0px;
      transform: translateX(-50%);
      @include respond(phone) {
        width: 25px;
        transform: none;
        top: 0;
        left: 0;
        transform: translate(-25%, -25%);
      }
    }
    &__icon {
      width: 10%;
      min-width: 60px;
      text-align: center;
      &__img {
        // margin-left: auto;
        max-height: 50px;
        min-height: 50px;
        max-width: 50px;
        min-width: 50px;
        object-fit: contain;
        border-radius: 4px;
        @include respond(phone) {
          width: 50px;
        }
      }
    }
    &__name {
      width: 100%;
      font-style: $title;
      font-weight: 700;
      @include respond(phone) {
        width: 50%;
      }
      & p {
        @include respond(phone) {
          max-width: 18ch;
          word-wrap: break-word;
        }
      }
      max-width: 500px;
      min-width: min-content;
      & h3 {
        text-transform: lowercase;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
    &__category {
      min-width: 200px;
      width: 20%;
      font-family: $subTitle;
      font-weight: 500;
      @include respond(phone) {
        width: 50%;
      }
      // min-width: min-content;
      min-width: 75px;
      // background-color: red;
      & p {
        text-transform: capitalize;
      }
    }
    &__ratings {
      font-family: $subTitle;
      font-weight: 500;
      width: 10%;
      min-width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: max-content;
      &__text {
        // white-space: nowrap;
        line-height: 15px;
        margin-left: 10px;
      }
    }
  }
}

.forgot__password__page{
    max-width: 1100px;
    margin: 50px auto;
    padding: 10px 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        & h1{
            margin: 10px 0px;
            align-self: flex-start;
            color: $onSurface-primary;
        }
        & h5{
            align-self: flex-start;
            color: red;
        }
        &__input{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
            & label{
                color: $onSurface-secondary;
                font-weight: bold;
                cursor: pointer;
            }
            & input{
                width: 100%;
                outline: none;
                border: none;
                border-bottom: 1px solid $onSurface-secondary;
                padding: 10px 6px;
                &:focus{
                    border-bottom: 2px solid $primary;
                }
            }
        }
        &__button{
            background-color: $primary;
            color: $onPrimary;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            align-self: flex-start;
            border: none;
            outline: none;
            position: relative;
            &:hover{
                background-color: $primaryHover;
            }
        }
    }
    
}
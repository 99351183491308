

.profile__intro{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    margin-top: 50px;
    padding: 10px;
    margin-bottom: 50px;
    width: 1100px;
    max-width: 100%;
    // background-color: red;
}

.profile__intro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    &__infoSection{
        display: flex;
        align-items: center;
        margin: 0px 50px;
        @include respond(phone){
            margin: 0;
        }
    }
    
    &__displayImage{
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        &__content{
            &__uploading{
                pointer-events: none;
            }
            &__img {
                width: 150px;
                height: 150px;
                & img{
                    max-height: 150px;
                    max-width: 150px;
                    min-width: 150px;
                    min-height: 150px;
                    object-fit: cover;
                    aspect-ratio: 1;
                }
            }
            &__upload{
                position: absolute;
                height: 0;
                opacity: 0.5;
                width: 100%;
                background-color: rgb(129, 129, 129);
                bottom: 0;
                transition: all 300ms;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                cursor: pointer;
                pointer-events: inherit;
                &__icon{
                    color: white;
                    font-size: 20px;
                    cursor: pointer !important;
                }
                &__text{
                    color: white;
                    cursor: inherit;
                }
                &__file{
                    position: absolute;
                    opacity: 1;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer !important;
                }
            }
            &:hover &__upload{
                height: 50%;
            }
        }
        &__loadingIndicator{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
    }
}

.profile__intro__nameAndAddress{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 10px;
    & h1{
        text-transform: capitalize;
    }
    & p{
        color: $primary;
        text-transform: lowercase;
    }
}

.profile__intro__nameAndAddress h1{
    font-weight: 700;
    font-style: 36px;
}

.profile__intro__nameAndAddress h3{
    color: $primary;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px; 
}

.profile__intro__detail{
    max-width: 600px;
    text-align: left;
    padding: 10px;
}

@media(max-width: 700px){
    .profile__intro{
        flex-direction: column;
    }
}


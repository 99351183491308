.filter{
    position: relative;
    width: 100%;
    min-width: 250px;
    &__div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $surface;
        height: 50px;
        border-radius: 10px;
        position: relative;
        &__div{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            & input{
                background-color: transparent;
                border: none;
                outline: none;
                padding-left:25px;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
                font-weight: 600;
                color: $onSurface-primary;
            }
        }

        &__icon{
            padding-right: 25px;
            cursor: pointer;
            &__svg{
                transition: all 300ms;
                &-asc{
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__options{
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background:$onPrimary;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: absolute;
        top: -10;   
        width: inherit;
        padding: 8px 12px;
        z-index: 75;
        &__filter{
            margin: 1rem 0;
            margin-left: 30px;
            position:relative;
            line-height: 15px;
            cursor: pointer;
            &::before{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 20%;
                border: 1px solid rgb(88, 88, 88);
                bottom: -1px;
                left: -20px;
            }

            &-selected{
                &::after{
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 20%;
                    left: -17px;
                    background-color: $primary;
                    bottom: 2px;
                }
            }

        }
        &__item{
            margin: 1rem 0;
            margin-left: 30px;
            position:relative;
            line-height: 15px;
            cursor: pointer;
            &::before{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 1px solid rgb(88, 88, 88);
                bottom: -1px;
                left: -20px;
            }
            &-selected{
                &::after{
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    left: -17px;
                    background-color: $onSurface-primary;
                    bottom: 2px;
                }
            }
        }

        & p{
            color: $primary;
            padding: 10px 0px;
            margin-left: 10px;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
        }
    }
}



.contact{
    max-width: 1000px;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    transform: translateY(100px);
    @include respond(tab){
        margin: 0 10px;
        flex-direction: column;
    }
    &__form{
        width: 70%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $background;
        @include respond(tab){
            width: 100%;
        }
        &__header{
            color: $onSurface-primary;
            & p{
                font-family: $round;
                font-weight: 400;
            }
        }
        &__fields{
            // display: flex;
            // justify-content: space-between;
            
        }
        &__upper{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin: 30px 0;
            @include respond(phone){
                flex-direction: column;
                margin-bottom: 0;
            }
        }
        &__input{
            border: none;
            outline: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding: 10px;
            width: 250px;
            margin: 0 10px;
            @include respond(phone){
                margin-bottom: 20px;
                width: 100%;
            }
            &::placeholder{
                color: $onSurface-secondary;
            }
            &-message{
                width: 100%;
                display: block;
                margin-bottom: 30px;
                @include respond(phone){
                    margin-bottom: 20px;
                }
            }
        }
        
        &__button{
            width: 130px;
            padding: 20px;    
            background-color: $primary;
            border: none;
            outline: none;
            border-radius: 10px;
            color: $onPrimary;
            cursor: pointer;
            position: relative;
            font-weight: 600;
            font-size: 16px;
            transition: all 300ms;
            &:hover{
                background-color: $primaryHover;
            }
        }
    }
    &__info{
        padding: 0 30px ;
        background-color: $primary;
        flex: 1;
        display: flex;
        align-items: center;
        &__item{
            display: flex;
            align-items: center;
            color: $onPrimary;
            font-size: 20px;
            margin: 40px 0;
            &__icon{
                margin-right: 20px;
                font-size: 30px;
            }
        }
    }
}



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
a{
  text-decoration: none;
  color: inherit;
  &:visited{
    color: inherit;
  }
}

input, textarea, input::placeholder, textarea::placeholder{
  font-family: $subTitle2;
}

h1{
  font-family: $round;
}
h2{
  font-family: $title;
}
h3{
  font-family: $title;
}
p, span, div{
  font-family: $subTitle2;

}
button{
  font-family: $subTitle2;
  cursor: pointer;
}

code{
  background-color: red;
}

body{
  font-family: $title;
}

.app{
  position: relative;
  // background-color: white;
  
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 10px rgba($primary, 1);
}
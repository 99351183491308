.review1{
    &__img-thumb{
        padding: 10px 14px;
        &__array{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }
    &__your-review{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $surface;
        width: 100%;
        border-radius: 10px;
        margin: 10px 0px;
        padding: 5px 10px;
        & textarea{
            flex: 1;
            background-color: transparent;
            border: 0px solid;
            padding: 0px 10px;
            padding-top: 15px;
            resize: none;
            font-size: 15px;
            &:focus{
                outline: none;
            }
        }
        &__icons{
            & input{
                display: none;
            }
            &__upload-icon{
                cursor: pointer;
                fill: $onSurface-secondary;
            }
            &__send-icon{
                // margin: 0 10px;
                cursor: pointer;
                fill: $onSurface-secondary;
                opacity: 0;
                transition: 300ms;
                margin: 0 10px;
                pointer-events: none;
            }
            &__show-send{
                opacity: 1;
                transition: 300ms;
                margin: 0 10px;
                cursor: pointer;
                fill: $onSurface-secondary;
                pointer-events: all;
            }
        }
    }
}
    
.comparison__page {
  max-width: $maxwidth;
  margin: auto;
  position: relative;
  // background-color: red;
  &__upper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-around;
    padding: 8px 12px;
    width: 100%;
    margin: 50px 0px;
    position: relative;
    @include respond(phone) {
      display: block;
    }
    // background-color: red;
  }
  &__lower {
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin: 20px 10px;

    & h2 {
      color: #357bce;
      padding: 20px;
    }
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 0 20px;
    border-radius: 20px;
    @include respond(phone) {
      margin: 50px auto;
      padding: 20px;
    }
  }
  &__block-1 {
    @include respond(phone) {
      margin-bottom: 30px;
    }
  }
  &__block-2 {
    @include respond(phone) {
      margin-bottom: 50px;
    }
  }
}

.comparison__background {
  &__vertical-dot {
    @include respond(phone) {
      display: none;
    }
  }
  &__horizantal-dot {
    @include respond(phone) {
      display: none;
    }
  }
  &__blue-zigzag {
    @include respond(phone) {
      display: none;
    }
  }
  &__blue-spiral {
    @include respond(phone) {
      display: none;
    }
  }
}

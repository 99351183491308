.loadingIndicator {
  color: rgb(22, 176, 236);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // backdrop-filter: blur(2px);
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon {
    font-size: inherit;
    @include infiniteRotate(infiniteRotate);
  }
}
